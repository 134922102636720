import {
  ApplyForRefundConfirmation,
  ApplyForRefundIneligible,
  ApplyForRefundPage,
} from '@ovotech/energy-cx';
import {
  GlobalErrorPage,
  PageWithNavigationAndSectionPadding,
} from '@/src/components';
import { PaygHoldingPage } from '@/src/components/PaygHoldingPage';
import {
  ONE_CLICK_PAYMENT_REDUCTION,
  SHOW_RECOMMENDED_DIRECT_DEBIT,
} from '@/src/constants/feature-flags';
import {
  EXTERNAL_ROUTE_HELP,
  EXTERNAL_ROUTE_SPOTLIGHTS,
  OLD_ROUTE_CHANGE_DD_AMOUNT,
  OLD_ROUTE_CHANGE_DD_DATE,
  ROUTE_ADJUST_DD_AMOUNT_CALCULATION,
  ROUTE_BILLING_DETAILS,
  ROUTE_BILLING_HISTORY,
  ROUTE_BOILER_COVER,
  ROUTE_CHANGE_DD_AMOUNT,
  ROUTE_CHANGE_DD_AMOUNT_CONFIRM,
  ROUTE_CHANGE_DD_DATE,
  ROUTE_CHANGE_DD_DATE_CONFIRM,
  ROUTE_DD_CALCULATOR,
  ROUTE_ENERGY_EXPERTS,
  ROUTE_ENERGY_INSIGHTS,
  ROUTE_EV,
  ROUTE_ANYTIME,
  ROUTE_GLOBAL_ERROR,
  ROUTE_GREENER_ENERGY,
  ROUTE_GREENER_ENERGY_SIGNUP,
  ROUTE_GREENER_ENERGY_SIGNUP_SUCCESS,
  ROUTE_GREENER_ELECTRICITY,
  ROUTE_GREENER_ELECTRICITY_SIGNUP,
  ROUTE_GREENER_ELECTRICITY_SIGNUP_SUCCESS,
  ROUTE_HELP,
  ROUTE_HOLDING,
  ROUTE_HOME,
  ROUTE_MAKE_CARD_PAYMENT,
  ROUTE_METER_READINGS,
  ROUTE_MOVE_IN,
  ROUTE_MOVING_HOME,
  ROUTE_MY_THERMOSTAT,
  ROUTE_OFFERS,
  ROUTE_ONE_CLICK_PAYMENT_REDUCTION_CONFIRMATION,
  ROUTE_OPENING_METER_READINGS,
  ROUTE_PAYG,
  ROUTE_PAYMENTS,
  ROUTE_PLAN,
  ROUTE_POWER_MOVE,
  ROUTE_PROFILE,
  ROUTE_RECOMMENDED_DD,
  ROUTE_REFUNDS_CONFIRM,
  ROUTE_REFUNDS_NOT_ELIGIBLE,
  ROUTE_REFUNDS_ONE_STOP,
  ROUTE_RENEWAL,
  ROUTE_RENEWAL_SVT,
  ROUTE_RENEWAL_SVT_COMM,
  ROUTE_SELECT_ACCOUNT,
  ROUTE_SETUP_DD,
  ROUTE_SMART_THERMOSTATS_OFFER,
  ROUTE_USAGE,
  ROUTE_CHARGE_ANYTIME_ELIGIBILITY,
} from '@/src/constants/routes';
import { BoilerCover } from '@/src/pages/BoilerCover';
import { EVsInlifePage, OvoDriveAnytimePage } from '@/src/pages/AnytimeInlife';
import { EnergyExpertsRedirect } from '@/src/pages/EnergyExperts/EnergyExpertsRedirect';
import { EnergyInsightsPages } from '@/src/pages/EnergyInsights';
import { MoveIn } from '@/src/pages/MoveIn/MoveIn';
import { OffersPage } from '@/src/pages/Offers/OffersPage';
import { AdjustDirectDebit } from '@/src/pages/Payments/DirectDebitCalculator/AdjustDirectDebit/AdjustDirectDebit';
import OneClickPaymentReductionConfirmation from '@/src/pages/Payments/OneClickPaymentReductionConfirmation';
import { SelectAccount } from '@/src/pages/SelectAccount';
import { SmartThermostatsOffer } from '@/src/pages/SmartThermostats/SmartThermostatsOffer';
import { OptimizelyFeature } from '@/src/utils/optimizely';
import React, { ComponentType } from 'react';
import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom-v5';
import {
  BillingDetailsPage,
  BillingHistory,
  ChangeDirectDebitAmount,
  ChangeDirectDebitAmountConfirm,
  ChangeDirectDebitDate,
  ChangeDirectDebitDateConfirm,
  DirectDebitCalculator,
  GreenerEnergy,
  GreenerEnergySignupPage,
  GreenerEnergySignupSuccess,
  GreenerElectricity,
  GreenerElectricitySignupPage,
  GreenerElectricitySignupSuccess,
  Holding,
  Home,
  MeterReadings,
  MovingHome,
  OpeningMeterReadings,
  Payments,
  Plan,
  Profile,
  RecommendedDirectDebit,
  Renewal,
  SetupDirectDebit,
  Shop,
  Usage,
} from '../pages';
import SmartThermostatsInLife from '../pages/SmartThermostats/pages/SmartThermostatsInLife';
import ChargeAnytime from '@/src/pages/AnytimeEligibility/ChargeAnytime';
import { CardPaymentSessionRoutes } from '@/src/pages/Payments/CardPaymentSession';
import { JoinPowerMove } from '@/src/pages/PowerMove/JoinPowerMove';

const OneClickPaymentReductionFeature = () => (
  <OptimizelyFeature feature={ONE_CLICK_PAYMENT_REDUCTION}>
    {isEnabled =>
      isEnabled ? (
        <OneClickPaymentReductionConfirmation />
      ) : (
        <Redirect to={ROUTE_HOME} />
      )
    }
  </OptimizelyFeature>
);

type RouteType = {
  path: string;
  component: ComponentType<any>;
  exact?: boolean;
};

export const routes: Array<RouteType> = [
  {
    path: ROUTE_HOME,
    component: Home,
    exact: true,
  },
  {
    path: '/shop',
    component: Shop,
  },
  {
    path: ROUTE_PROFILE,
    component: Profile,
  },
  {
    path: '/your-energy',
    component: () => <Redirect to={ROUTE_HOME} />,
    exact: true,
  },
  {
    path: '/your-profile',
    component: () => <Redirect to={ROUTE_PROFILE} />,
    exact: true,
  },
  {
    path: ROUTE_SELECT_ACCOUNT,
    component: SelectAccount,
    exact: true,
  },
  {
    path: ROUTE_PAYG,
    component: PaygHoldingPage,
    exact: true,
  },
  {
    path: ROUTE_GLOBAL_ERROR,
    component: GlobalErrorPage,
    exact: true,
  },
  {
    path: ROUTE_HELP,
    component: () => {
      // Currently just pointing to prod, may need to point to UAT in future?
      window.location.replace(EXTERNAL_ROUTE_HELP);
      return null;
    },
    exact: true,
  },
  {
    path: ROUTE_PLAN,
    component: Plan,
    exact: true,
  },
  {
    path: ROUTE_HOLDING,
    component: Holding,
    exact: true,
  },
  {
    path: '/greener-energy-signup',
    component: withRouter(({ location }: RouteComponentProps) => (
      <Redirect
        to={{
          pathname: ROUTE_GREENER_ENERGY_SIGNUP,
          search: location.search,
        }}
      />
    )) as any,
    exact: true,
  },
  {
    path: ROUTE_GREENER_ENERGY,
    component: GreenerEnergy,
    exact: true,
  },
  {
    path: ROUTE_GREENER_ENERGY_SIGNUP,
    component: GreenerEnergySignupPage,
    exact: true,
  },
  {
    path: ROUTE_GREENER_ENERGY_SIGNUP_SUCCESS,
    component: GreenerEnergySignupSuccess,
    exact: true,
  },
  {
    path: ROUTE_GREENER_ELECTRICITY,
    component: GreenerElectricity,
    exact: true,
  },
  {
    path: ROUTE_GREENER_ELECTRICITY_SIGNUP,
    component: GreenerElectricitySignupPage,
    exact: true,
  },
  {
    path: ROUTE_GREENER_ELECTRICITY_SIGNUP_SUCCESS,
    component: GreenerElectricitySignupSuccess,
    exact: true,
  },
  {
    path: ROUTE_PAYMENTS,
    component: Payments,
    exact: true,
  },
  {
    path: ROUTE_ADJUST_DD_AMOUNT_CALCULATION,
    component: ChangeDirectDebitAmount,
    exact: true,
  },
  {
    path: ROUTE_ONE_CLICK_PAYMENT_REDUCTION_CONFIRMATION,
    component: OneClickPaymentReductionFeature,
    exact: true,
  },
  {
    path: ROUTE_CHANGE_DD_AMOUNT,
    component: () => (
      <OptimizelyFeature feature={SHOW_RECOMMENDED_DIRECT_DEBIT}>
        {isEnabled =>
          isEnabled ? <AdjustDirectDebit /> : <ChangeDirectDebitAmount />
        }
      </OptimizelyFeature>
    ),
    exact: true,
  },

  {
    path: OLD_ROUTE_CHANGE_DD_AMOUNT,
    component: () => (
      <OptimizelyFeature feature={SHOW_RECOMMENDED_DIRECT_DEBIT}>
        {isEnabled =>
          isEnabled ? <DirectDebitCalculator /> : <ChangeDirectDebitAmount />
        }
      </OptimizelyFeature>
    ),
    exact: true,
  },
  {
    path: ROUTE_CHANGE_DD_AMOUNT_CONFIRM,
    component: ChangeDirectDebitAmountConfirm,
    exact: true,
  },
  {
    path: OLD_ROUTE_CHANGE_DD_DATE,
    component: () => <Redirect to={ROUTE_CHANGE_DD_DATE} />,
    exact: true,
  },
  {
    path: ROUTE_CHANGE_DD_DATE,
    component: ChangeDirectDebitDate,
    exact: true,
  },
  {
    path: ROUTE_CHANGE_DD_DATE_CONFIRM,
    component: ChangeDirectDebitDateConfirm,
    exact: true,
  },
  {
    path: ROUTE_DD_CALCULATOR,
    component: () => (
      <OptimizelyFeature feature={SHOW_RECOMMENDED_DIRECT_DEBIT}>
        {isEnabled =>
          isEnabled ? <DirectDebitCalculator /> : <ChangeDirectDebitAmount />
        }
      </OptimizelyFeature>
    ),
    exact: true,
  },
  {
    path: ROUTE_RECOMMENDED_DD,
    component: () => (
      <OptimizelyFeature feature={SHOW_RECOMMENDED_DIRECT_DEBIT}>
        {isEnabled =>
          isEnabled ? <RecommendedDirectDebit /> : <Redirect to={ROUTE_HOME} />
        }
      </OptimizelyFeature>
    ),
    exact: true,
  },
  {
    path: ROUTE_MAKE_CARD_PAYMENT,
    component: () => <CardPaymentSessionRoutes />,
  },
  {
    path: ROUTE_REFUNDS_ONE_STOP,
    component: () => (
      <PageWithNavigationAndSectionPadding title="Apply for your refund">
        <ApplyForRefundPage />
      </PageWithNavigationAndSectionPadding>
    ),
    exact: true,
  },
  {
    path: ROUTE_REFUNDS_CONFIRM,
    component: () => (
      <PageWithNavigationAndSectionPadding title="Confirmation - Apply for a refund">
        <ApplyForRefundConfirmation />
      </PageWithNavigationAndSectionPadding>
    ),
    exact: true,
  },
  {
    path: ROUTE_REFUNDS_NOT_ELIGIBLE,
    component: () => (
      <PageWithNavigationAndSectionPadding title="Not eligible - Apply for a refund">
        <ApplyForRefundIneligible />
      </PageWithNavigationAndSectionPadding>
    ),
    exact: true,
  },
  {
    path: ROUTE_USAGE,
    component: Usage,
  },
  {
    path: '/charges',
    component: () => <Redirect to={ROUTE_BILLING_HISTORY} />,
    exact: true,
  },
  {
    path: '/charges/:year/:month',
    component: withRouter(
      ({
        match: {
          params: { year, month },
        },
      }: RouteComponentProps<{ year: string; month: string }>) => (
        <Redirect to={`${ROUTE_BILLING_DETAILS}/${year}/${month}`} />
      ),
    ),
    exact: true,
  },
  {
    path: '/billing/:year/:month',
    component: withRouter(
      ({
        match: {
          params: { year, month },
        },
      }: RouteComponentProps<{ year: string; month: string }>) => (
        <Redirect to={`${ROUTE_BILLING_DETAILS}/${year}/${month}`} />
      ),
    ),
    exact: true,
  },
  {
    path: ROUTE_BILLING_DETAILS,
    component: () => <BillingDetailsPage />,
  },
  {
    path: '/billing',
    component: () => <Redirect to={ROUTE_BILLING_DETAILS} />,
    exact: true,
  },
  {
    path: `/billing/:index(\\d+)`,
    component: withRouter(props => {
      const { index } = props.match.params;

      return <Redirect to={`${ROUTE_BILLING_DETAILS}/${index}`} />;
    }),
  },
  {
    path: ROUTE_BILLING_HISTORY,
    component: BillingHistory,
  },
  {
    path: ROUTE_MOVING_HOME,
    component: () => <MovingHome />,
  },
  {
    path: ROUTE_MOVE_IN,
    component: MoveIn,
  },
  {
    path: ROUTE_SETUP_DD,
    component: SetupDirectDebit,
  },
  {
    path: ROUTE_RENEWAL_SVT_COMM,
    component: () => <Redirect to={ROUTE_RENEWAL_SVT} />,
  },
  {
    path: ROUTE_RENEWAL,
    component: Renewal,
  },
  {
    path: ROUTE_METER_READINGS,
    component: MeterReadings,
  },
  {
    path: ROUTE_OPENING_METER_READINGS,
    component: OpeningMeterReadings,
  },
  {
    path: '/zero-in',
    component: () => {
      window.location.replace(EXTERNAL_ROUTE_SPOTLIGHTS);
      return null;
    },
  },
  {
    path: ROUTE_MY_THERMOSTAT,
    component: SmartThermostatsInLife,
  },
  {
    path: ROUTE_BOILER_COVER,
    component: BoilerCover,
  },
  {
    path: ROUTE_OFFERS,
    component: OffersPage,
    exact: true,
  },
  {
    path: ROUTE_SMART_THERMOSTATS_OFFER,
    component: SmartThermostatsOffer,
  },
  {
    path: ROUTE_EV,
    component: EVsInlifePage,
  },
  {
    path: ROUTE_CHARGE_ANYTIME_ELIGIBILITY,
    component: ChargeAnytime,
  },
  {
    path: ROUTE_ANYTIME,
    component: OvoDriveAnytimePage,
  },
  {
    path: ROUTE_POWER_MOVE,
    component: JoinPowerMove,
  },
  {
    path: ROUTE_ENERGY_INSIGHTS.CARBON_INTENSITY,
    component: EnergyInsightsPages.CarbonIntensity,
  },
  {
    path: ROUTE_ENERGY_INSIGHTS.BILL_BREAKDOWN,
    component: EnergyInsightsPages.BillBreakdown,
  },
  {
    path: ROUTE_ENERGY_INSIGHTS.HUB,
    component: EnergyInsightsPages.Hub,
  },
  {
    path: ROUTE_ENERGY_INSIGHTS.TERMS,
    component: EnergyInsightsPages.Terms,
  },
  {
    path: ROUTE_ENERGY_EXPERTS,
    component: EnergyExpertsRedirect,
  },
];
