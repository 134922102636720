import { datadogRum, RumInitConfiguration } from '@datadog/browser-rum';

export const beforeSend: RumInitConfiguration['beforeSend'] = (
  event,
): boolean => {
  event.view.url = event.view.url
    .replace(/(refresh_token=)[^&]+/, `$1REDACTED`)
    .replace(/(code=)[^&]+/, `$1REDACTED`);
  return true;
};

export const init = () => {
  if (
    !(process.env.NODE_ENV === 'development') &&
    process.env.NX_DATADOG_APPLICATION_ID &&
    process.env.NX_DATADOG_CLIENT_TOKEN
  ) {
    datadogRum.init({
      applicationId: process.env.NX_DATADOG_APPLICATION_ID,
      clientToken: process.env.NX_DATADOG_CLIENT_TOKEN,
      site: 'datadoghq.com',
      service: 'ovo-account-web',
      env: process.env.VERCEL_ENV === 'preview' ? 'staging' : 'prod',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 0,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
      enablePrivacyForActionName: true,
      version: process.env.VERCEL_DEPLOYMENT_ID ?? undefined,
      beforeSend,
    });
  } else {
    console.info('Datadog is not initialised');
  }
};

export const reportError = (error: unknown, context?: object) => {
  datadogRum.addError?.(error, context);
};

export const setInitialUserContext = (
  id: string | null | undefined,
  context?: object,
) => {
  if (id) {
    datadogRum.setUser({ id });
    // Replace the default context for all your RUM events
    if (context) {
      datadogRum.setGlobalContext(context);
    }
  }
};

export const trackUserAction = (name: string, context?: object) => {
  datadogRum.addAction(name, context);
};
