/*
This file is used in conjunction with the cp-ui-tools analytics package.
This is used to describe the _subjects_ of events - do not store separate names for events relating to the same subject.
The events produced by the cp-ui-tools package provides a type (click, page, view, render), which can be used to automatically generate an event name
Avoid nesting properties as this could hide duplicated names
*/

export const CONTACT_US_LINK = 'ContactUsLink';
export const ACTION_CENTRE = 'ActionCentre';
export const ACTION_CENTRE_DISMISS = 'ActionCentreDismiss';

export const REC_DD_WIDGET = 'RecommendedDirectDebitWidget';
export const PACE_BOOTSTRAP = 'PaceBootstrap';
export const MANAGE_YOUR_PAYMENTS_LINK = 'ManageYourPaymentsLink';
export const COLLECTABLE_BALANCE_SUMMARY = 'CollectableBalanceSummary';
export const UNDERSTANDING_YOUR_BALANCE_LINK = 'UnderstandingYourBalanceLink';
export const DEBT_SENSE_LINK = 'DebtSenseLink';
export const CURRENT_PERIOD_VIEW_DETAILS_LINK = 'CurrentPeriodViewDetailsLink';
export const LATEST_BILLING_PERIOD = 'LatestBillingPeriod';
export const LAST_COMPLETE_BILLING_PERIOD = 'LastCompleteBillingPeriod';
export const MENU_PLAN_DETAILS_LINK = 'MenuPlanDetailsLink';
export const MENU_GREENER_ENERGY_LINK = 'MenuGreenerEnergyLink';
export const MENU_GREENER_ELECTRICITY_LINK = 'MenuGreenerElectricityLink';
export const MENU_PLAN_DROPDOWN = 'MenuPlanDropdown';
export const MENU_OVO_GREENLIGHT_LINK = 'MenuOvoGreenlightLink';
export const GREENLIGHT_BANNER = 'GreenlightBanner';
export const GREENLIGHT_LINK = 'GreenlightLink';
export const SET_UP_DIRECT_DEBIT_LINK = 'SetUpDirectDebitLink';
export const FOOTER_LINK = 'FooterLink';

export const GLOBAL_ERROR_PAGE = 'GlobalErrorPage';
export const GLOBAL_ERROR_LINK = 'GlobalErrorLink';

export const MAINTENANCE_MODE_PAGE = 'MaintenanceModePage';

export const DELETE_COOKIE_BUTTON = 'DeleteCookieButton';

export const EVS_BP_PULSE_PRODUCT_REVEAL = 'EvsBpPulseProductReveal';
export const EVS_BP_PULSE_PRODUCT_REVEAL_ERROR = 'EvsBpPulseProductRevealError';
export const EVS_BP_PULSE_PRODUCT_REVEAL_LOADING =
  'EvsBpPulseProductRevealLoading';

export const EVS_OVO_DRIVE_ANYTIME_ELIGIBILITY =
  'EvsOvoDriveAnytimeEligibility';

export const EVS_INLIFE_MONTHLY = 'EvsInlifeMonthly';
export const EVS_INLIFE_INSIGHTS = 'EvsInlifeInsightsPage';
export const EVS_INLIFE_NEWS = 'EvsInlifeNewsPage';
export const EVS_INLIFE_COLLECTING_MONTHLY_DATA =
  'EvsInlifeCollectingMonthlyData';
export const EVS_INLIFE_MONTHLY_ERROR = 'EvsInlifeMonthlyError';
export const EVS_INLIFE_MONTHLY_NO_CONNECTION = 'EvsInlifeMonthlyNoConnection';
export const EVS_INLIFE_YEARLY = 'EvsInlifeYearly';
export const EVS_INLIFE_ANYTIME = 'EvsInlifeAnytime';

export const EVS_INLIFE_DISCONNECT = 'EvsInlifeDisconnect';

export const EVS_INLIFE_SHOW_MORE_CHART = 'EvsInlifeShowMoreUsageGraph';
export const EVS_INLIFE_DISCONNECT_LINK = 'EvsInlifeDisconnectLink';
export const EVS_INLIFE_DISCONNECT_CANCEL_LINK =
  'EvsInlifeDisconnectCancelLink';
export const EVS_INLIFE_DISCONNECT_CONFIRMED_LINK =
  'EvsInlifeDisconnectConfirmedLink';
export const EVS_INLIFE_DISCONNECT_CONFIRMED = 'EvsInlifeDisconnectConfirmed';
export const EVS_INLIFE_MONTHLY_NAVIGATION_PREVIOUS =
  'EvsInlifeMonthlyNavigationPreviousLink';
export const EVS_INLIFE_MONTHLY_NAVIGATION_NEXT =
  'EvsInlifeMonthlyNavigationNextLink';
export const EVS_INLIFE_YEARLY_NAVIGATION_PREVIOUS =
  'EvsInlifeYearlyNavigationPreviousLink';
export const EVS_INLIFE_YEARLY_NAVIGATION_NEXT =
  'EvsInlifeYearlyNavigationNextLink';
export const SETUP_DD_CALCULATOR = 'SetupDdCalculator';
export const SETUP_DD_VIEW_ERROR = 'SetupDdViewError';
export const AMEND_DD_CONFIRM = 'AmendDdConfirm';
export const AMEND_DD_AMOUNT = 'AmendDdAmount';
export const AMEND_DD_DATE = 'AmendDdDate';
export const AMEND_DD_DATE_CANCEL = 'AmendDdDateCancel';
export const AMEND_DD_DATE_SUBMIT = 'AmendDdDateSubmit';
export const AMEND_DD_DATE_CONFIRMATION = 'AmendDdDateConfirmation';
export const AMEND_DD_DATE_ERROR = 'AmendDdDateError';
export const AMEND_DD_DATE_CONFIRMATION_LINK = 'AmendDdDateConfirmationLink';
export const HOME_CHANGE_DD = 'HomeChangeDirectDebit';
export const PAYMENTS_CHANGE_DD = 'PaymentsChangeDirectDebit';
export const STRUGGLING_TO_PAY_DD_TOGGLE = 'DirectDebitStrugglingToPay';

export const DD_CALCULATOR = 'DdCalculator';

export const DOWNLOAD_BILL = 'DownloadBill';

export const HOME_SCREEN = 'HomeScreen';

export const PAYMENTS_SCREEN = 'PaymentsScreen';

export const REFUND_CONFIRMATION = 'RefundConfirmation';
export const NOT_ELIGIBLE_FOR_REFUND = 'NotEligibleForRefund';

export const TOP_UP_PAYMENT = 'TopUpPayment';

export const SMART_THERMOSTATS_PRODUCTS = 'SmartThermostatsProducts';
export const SMART_THERMOSTATS_PRODUCTS_HERO = 'SmartThermostatsProductsHero';
export const SMART_THERMOSTATS_PRODUCTS_BUY_NOW =
  'SmartThermostatsProductsBuyNow';

export const MY_THERMOSTAT_GET_A_SMART_METER_PAGE =
  'MyThermostatGetASmartMeterPage';
export const MY_THERMOSTAT_BOOK_APPOINTMENT_BUTTON =
  'MyThermostatBookAppointmentButton';
export const THERMOSTATS_WAITLIST_BUTTON = 'ThermostatsWaitlistButton';

export const PRIVACY_POLICY_LINK = 'PrivacyPolicyLink';
export const HOW_WE_USE_YOUR_DATA_LINK = 'HowWeUseYourDataLink';

export const RENEWAL_EOC1 = 'RenewalEoc1';
export const RENEWAL_EOC1_DOWNLOAD = 'RenewalEoc1Download';
export const RENEWAL_SVT = 'RenewalSvt';
export const RENEWAL_TARIFFS = 'RenewalTariffs';
export const RENEWAL_TARIFF_DETAIL = 'RenewalTariffDetail';
export const RENEWAL_TARIFF_MODAL = 'RenewalTariffModal';
export const RENEWAL_TC = 'RenewalTermsAndConditions';
export const RENEWAL_FAQ = 'RenewalFAQ';
export const RENEWAL_SUBMIT = 'RenewalSubmit';
export const RENEWAL_SUBMIT_SVT = 'RenewalSubmitSvt';
export const RENEWAL_NAV_TO_OFFERS = 'RenewalNavToOffers';
export const RENEWAL_CONFIRMATION = 'RenewalConfirmation';
export const RENEWAL_SUCCESS = 'RenewalSuccess';
export const RENEWAL_SUCCESS_SVT = 'RenewalSuccessSvt';
export const RENEWAL_OFFERS_PAGE = 'RenewalOffersPage';
export const RENEWAL_ONE_CLICK = 'RenewalOneClick';
export const RENEWAL_NAV_TO_PLAN = 'RenewalNavToPlan';
export const RENEWAL_UPSELL = 'RenewalOffers';
export const RENEWAL_ERROR = 'RenewalError';
export const RENEWAL_EXTERNAL_LINK = 'RenewalExternalLink';
export const RENEWAL_CURRENT_PLAN_DETAILS = 'RenewalCurrentPlanDetails';
export const RENEWAL_HOMEPLAN_BUNDLE = 'RenewalHomeplanBundle';
export const RENEWAL_BUNDLE_MODAL = 'RenewalBundleModal';
export const FIXED_PLAN_BANNER = 'RenewalFixedPlan';

export const WELCOME_MODAL_INTRO_PAGE = 'WelcomeModalIntroPage';
export const WELCOME_MODAL_CTA = 'WelcomeModalCTA';
export const WELCOME_MODAL_DISMISS = 'WelcomeModalDismiss';

export const WELCOME_BANNER_FAQ = 'WelcomeBannerFaq';
export const WELCOME_BANNER_TAKE_TOUR = 'WelcomeBannerTakeTour';

export const WELCOME_TOUR_DISMISS = 'TourDismiss';
export const WELCOME_TOUR_SCREEN = 'TourScreen';
export const WELCOME_TOUR_NEXT = 'TourNext';
export const WELCOME_TOUR_BACK = 'TourBack';
export const WELCOME_TOUR_HEADING = 'TourHeading';

export const METER_READINGS = 'MeterReadings';
export const METER_READINGS_VALIDATION_PROMPT = 'MeterReadingValidationPrompt';
export const METER_READINGS_HISTORY_PAGE_ERROR =
  'MeterReadingsHistoryPageError';
export const METER_READINGS_SUBMIT_PAGE_ERROR = 'MeterReadingsSubmitPageError';
export const METER_READINGS_CONFIRMATION_PAGE_ERROR =
  'MeterReadingsConfirmationPageError';
export const METER_READINGS_CONFIRMATION_SUBMIT_CTA =
  'MeterReadingsConfirmationSubmitCta';
export const METER_READINGS_EXAMPLES_LINK = 'MeterReadingsExamplesLink';

export const HOME_MOVES_NOT_CANCEL_DD = 'HomeMovesNotCancelDD';
export const HOME_MOVES_GET_QUOTE = 'HomeMovesGetQuote';
export const HOME_MOVES_SWITCH_TO_MANUAL_ENTRY = 'SwitchToManualEntry';
export const HOME_MOVES_SWITCH_TO_POSTCODE_LOOKUP = 'SwitchToPostcodeLookup';
export const HOME_MOVES_FIND_ADDRESS = 'FindAddress';

export const PROFILE_ADDON_CANCEL = 'ProfileAddonCancel';
export const PROFILE_ADDON_CANCELLED = 'ProfileAddonCancelled';
export const PROFILE_ADDON_CANCEL_KEEP = 'ProfileAddonCancelKeep';

export const PROFILE_ADDON_GREENER_ENERGY_MODAL_CLOSE =
  'ProfileAddonGreenerEnergyModalClose';

export const PROFILE_ADDON_GREENER_ELECTRICITY_MODAL_CLOSE =
  'ProfileAddonGreenerElectricityModalClose';

export const GREENER_ENERGY_SIGNUP = 'GreenerEnergySignup';
export const GREENER_ENERGY_SIGNUP_PURCHASE_BUTTON =
  'GreenerEnergyPurchaseButton';
export const GREENER_ENERGY_SIGNUP_SUCCESS = 'GreenerEnergySignupSuccess';
export const GREENER_ENERGY_SIGNUP_ERROR = 'GreenerEnergySignupError';
export const GREENER_ENERGY = 'GreenerEnergy';
export const GREENER_ENERGY_ACTION_CENTRE_BANNER =
  'GreenerEnergyActionCentreBanner';
export const HANDRAISER_BANNER = 'HandraiserBanner';
export const ENERGY_EXPERT_VISITS_BANNER = 'EnergyExpertVisitsBanner';

export const GREENER_ENERGY_SIGNUP_TREE_PLANTING_CARD =
  'TreePlantingSignupCard';
export const GREENER_ENERGY_SIGNUP_TREE_PLANTING_CARD_MODAL =
  'TreePlantingSignupCardModal';

export const GREENER_ENERGY_SIGNUP_CARBON_NEUTRAL_CARD =
  'CarbonNeutralSignupCard';
export const GREENER_ENERGY_SIGNUP_CARBON_NEUTRAL_CARD_MODAL =
  'CarbonNeutralSignupCardModal';

export const GREENER_ENERGY_CARBON_NEUTRAL_CARD = 'CarbonNeutralCard';

export const GREENER_ELECTRICITY = 'GreenerElectricity';
export const GREENER_ELECTRICITY_SIGNUP = 'GreenerElectricitySignup';
export const GREENER_ELECTRICITY_SIGNUP_PURCHASE_BUTTON =
  'GreenerElectricityPurchaseButton';
export const GREENER_ELECTRICITY_SIGNUP_ERROR = 'GreenerElectricitySignupError';
export const GREENER_ELECTRICITY_SIGNUP_SUCCESS =
  'GreenerElectricitySignupSuccess';
export const GREENER_ELECTRICITY_CARBON_NEUTRAL_CARD =
  'GreenerElectricityCarbonNeutralCard';
export const GREENER_ELECTRICITY_SIGNUP_CARBON_NEUTRAL_CARD =
  'GreenerElectricityCarbonNeutralSignupCard';
export const GREENER_ELECTRICITY_SIGNUP_TREE_PLANTING_CARD =
  'GreenerElectricityTreePlantingSignupCard';
export const GREENER_ELECTRICITY_SIGNUP_TREE_PLANTING_CARD_MODAL =
  'GreenerElectricityTreePlantingSignupCardModal';

export const PAGE_NOT_FOUND = 'PageNotFound';

export const PLAN_PAGE_TERMS_LINK = 'PlanPageTermsLink';

export const ACCOUNT_SELECTOR = 'AccountSelector';
export const ACCOUNT_SELECTOR_NO_ACCOUNT_WARNING =
  'AccountSelectorNoAccountWarning';

export const ANYTIME_ERROR = 'AnytimeError';

export const PAYG_LINK = 'PaygLink';
export const PAYG_SCREEN = 'PaygScreen';
export const PAYG_SELECT_ACCOUNT = 'PaygSelectAccount';
export const PAYG_PLAY_STORE_LINK = 'PaygPlayStoreLink';
export const PAYG_APP_STORE_LINK = 'PaygAppStoreLink';

export const HOMEPLAN_ACTION_CENTRE_BANNER = 'HomeplanActionCentreBanner';
export const HOMEPLAN_ACTION_FOUR_MONTH_FREE_CENTRE_BANNER =
  'HomeplanActionFourMonthsFreeCentreBanner';
export const HOMEPLAN_ACTION_FOUR_MONTH_FREE_PLUS_CREDIT_CENTRE_BANNER =
  'HomeplanActionFourMonthsFreePlusCreditCentreBanner';
export const HOMEPLAN_NAV_LINK = 'HomeplanNavLink';
export const HOMEPLAN_PROMO_OFFER_CARD = 'HomeplanPromoOfferCard';
export const HOMEPLAN_DEFAULT_CARD = 'HomeplanDefaultCard';
export const HOMEPLAN_FOUR_MONTHS_FREE_CARD = 'HomeplanFourMonthsFreeCard';
export const HOMEPLAN_THREE_MONTHS_FREE_CARD = 'HomeplanThreeMonthsFreeCard';
export const HOMEPLAN_SIX_MONTHS_FREE_CARD = 'HomeplanSixMonthsFreeCard';
export const HOME_PLAN_FIXED_PLAN_ADDON_Q1_OFFER =
  'HomePlanFixedPlanAddOnQ1Offer';
export const HOME_PLAN_SVT_PLAN_ADDON_Q1_OFFER = 'HomePlanSVTPlanAddOnQ1Offer';
export const HOMEPLAN_SVT_PLUS_BC_CARD = 'HomeplanSVTBCCard';
export const HOMEPLAN_SVT_PLUS_BC_CARD_BLACK_FRIDAY =
  'HomeplanSVTBCCardBlackFriday';
export const HOMEPLAN_SVT_PLUS_BC_CARD_Q1_PROMO_OFFER =
  'HomeplanSVTBCCardQ1PromoOffer';
export const HOMEPLAN_FIXED_PLUS_BC_CARD_Q1_PROMO_OFFER =
  'HomeplanFixedBCCardQ1PromoOffer';
export const HOMEPLAN_BLACK_FRIDAY_FIXED_OFFER =
  'HomeplanBlackFridayFixedOfferCard';
export const HOMEPLAN_FIXED_PLUS_BC_CARD = 'HomeplanFixedBCCard';
export const HOMEPLAN_FIXED_CONTRACT_Q1_PROMO_OFFER =
  'HomePlanFixedContractQ1PromoOfferCard';
export const HOME_PLAN_ENERGY_CREDITS_PROMO_FIXED =
  'HomePlanEnergyCreditsPromoFixed';
export const HOMERECOVER_NAV_LINK = 'HomeRecoverNavLink';

export const HOME_RECOVER_CARD = 'HomeRecoverCard';
export const HOME_RECOVER_Q1_OFFER_CARD = 'HomeRecoverQ1OfferCard';

export const ONBOARDING = 'Onboarding';

export const ONE_CLICK_PAYMENT_REDUCTION_BUTTON = 'ReducePaymentCTA';
export const ONE_CLICK_PAYMENT_REDUCTION_VIEW = 'OneClickPaymentReduction';
export const ONE_CLICK_PAYMENT_CONFIRMATION_VIEW =
  'OneClickPaymentReductionConfirm';

export const INTERCEPT_METER_READ_LINK = 'InterceptMeterReadingCTA';
export const INTERCEPT_SOMETHING_ELSE_LINK = 'InterceptSomethingElseLink';
export const INTERCEPT_PAGE_VIEWED = 'Intercept';
