import { Modal } from '@ovotech/nebula';

import React, { useState } from 'react';
import { HomeProfileData } from '../../types';
import { HomeProfileForm } from './HomeProfileForm/HomeProfileForm';
import { HomeProfileOutro } from './HomeProfileOutro/HomeProfileOutro';

enum JOURNEY_STATE {
  FORM = 'form',
  OUTRO = 'outro',
}

type Props = {
  homeProfile: HomeProfileData | null;
  onClose: () => void;
};

export const HomeProfileModal = ({ homeProfile, onClose }: Props) => {
  const [journeyState, setJourneyState] = useState(JOURNEY_STATE.FORM);

  return (
    <Modal title="" isOpen onClose={onClose}>
      {journeyState === JOURNEY_STATE.FORM ? (
        <HomeProfileForm
          existingHomeProfileData={homeProfile}
          onBackFromFirstQuestion={onClose}
          onNextFromLastQuestion={() => setJourneyState(JOURNEY_STATE.OUTRO)}
        />
      ) : (
        <HomeProfileOutro onClickCTA={onClose} />
      )}
    </Modal>
  );
};
