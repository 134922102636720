import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { SmartChargers } from './anytime-chargers';
import {
  eligibilityResponse,
  IneligibleReasons,
  useEligibilityCheck,
} from './use-eligibility-check';
import { EligibilityForPayAsYouDrive, EligibilityQuestions } from './types';
import {
  eligibleForPromo,
  eligibleForVWGPromo,
  PromoType,
} from './anytime-vehicles';
import { getIntegrationType, IntegrationType } from './integrations';

interface FeatureFlags {
  isPayAsYouDriveEnabled: boolean;
  isPayAsYouDriveVolvoEnabled: boolean;
  isPayAsYouDriveVolvoOctEnabled: boolean;
  isPayAsYouDriveVolvoNovEnabled: boolean;
  isPayAsYouDriveVWGEnabled: boolean;
  isIndraChargeAnytimeAppEnabled: boolean;
}

interface SmartMeterFlags {
  hasSmartMeter: boolean;
}

interface ChargeAnytimeProps extends FeatureFlags, SmartMeterFlags {
  useCheckEligibilityForPayAsYouDrive(): EligibilityForPayAsYouDrive;
}

export interface ChargeAnytimeContextValue
  extends EligibilityQuestions,
    FeatureFlags,
    SmartMeterFlags {
  setSmartCharger(smartCharger: SmartChargers): void;
  setMake(make: string | null): void;
  setModel(model: string | null): void;
  setYear(year: number | null): void;
  setHasInternetAccess(hasInternetAccess: boolean | null): void;
  setHasSolarPanels(hasSolarPanels: boolean | null): void;
  eligibility: eligibilityResponse & Partial<IneligibleReasons>;
  isError: boolean;
  isFetching: boolean;
  ineligibleNotSmartMeter: boolean;
  ineligibleInvalidMeterReadFrequency: boolean;
  ineligibleOnlyGas: boolean;
  ineligibleOther: boolean;
  integrationType: IntegrationType;
  // volvo
  isEligibleVolvo: boolean | PromoType;
  // vwg
  isEligibleVWG: boolean | PromoType;
  vwgOrderNumber: string;
  setVwgOrderNumber(vwgOrderNumber: string): void;
  vwgOrderNumberError: string;
  setVwgOrderNumberError(vwgOrderNumberError: string): void;
  // promo code
  promoCode: string;
  validPromoCode: string;
  setPromoCode: (promoCode: string) => void;
  isPromoCodeError: boolean;
  validatePromoCode(promoCodeCheck: boolean): void;
}

export const ChargeAnytimeContext =
  createContext<ChargeAnytimeContextValue | null>(null);

export const useChargeAnytimeContext = (): ChargeAnytimeContextValue => {
  const contextValue = useContext(ChargeAnytimeContext);
  if (contextValue === null) {
    throw new Error('Must be used inside ChargeAnytime Context');
  }
  return contextValue;
};

export const ChargeAnytimeProvider = ({
  children,
  useCheckEligibilityForPayAsYouDrive,
  hasSmartMeter,
  isPayAsYouDriveVolvoEnabled,
  isPayAsYouDriveVolvoOctEnabled,
  isPayAsYouDriveVolvoNovEnabled,
  isPayAsYouDriveEnabled,
  isPayAsYouDriveVWGEnabled,
  isIndraChargeAnytimeAppEnabled,
}: PropsWithChildren<ChargeAnytimeProps>) => {
  const [smartCharger, setSmartCharger] = useState<SmartChargers | null>(null);
  const [make, setMake] = useState<string | null>(null);
  const [model, setModel] = useState<string | null>(null);
  const [year, setYear] = useState<number | null>(null);
  const [hasInternetAccess, setHasInternetAccess] = useState<boolean | null>(
    null
  );
  const [hasSolarPanels, setHasSolarPanels] = useState<boolean | null>(null);

  const [promoCode, setPromoCode] = useState('');
  const [validPromoCode, setValidPromoCode] = useState('');
  const [isPromoCodeError, setIsPromoCodeError] = useState(false);

  const [vwgOrderNumber, setVwgOrderNumber] = useState('');
  const [vwgOrderNumberError, setVwgOrderNumberError] = useState('');

  const {
    ineligibleNotSmartMeter,
    ineligibleInvalidMeterReadFrequency,
    ineligibleOnlyGas,
    isFetching,
    isError,
    ineligibleOther,
  } = useCheckEligibilityForPayAsYouDrive();

  const eligibility = useEligibilityCheck({
    make,
    model,
    year,
    smartCharger,
    hasSolarPanels,
    hasInternetAccess,
    ineligibleNotSmartMeter,
    ineligibleOnlyGas,
    hasSmartMeter,
    ineligibleInvalidMeterReadFrequency,
    ineligibleOther,
    isPayAsYouDriveEnabled,
    isPayAsYouDriveVolvoEnabled,
    isPayAsYouDriveVolvoOctEnabled,
    isPayAsYouDriveVolvoNovEnabled,
  });

  const isEligibleVolvo = useMemo(() => {
    return (
      isPayAsYouDriveVolvoEnabled &&
      make !== null &&
      eligibleForPromo(
        make,
        model,
        year,
        smartCharger,
        !!hasSolarPanels,
        !!hasInternetAccess,
        {
          isPayAsYouDriveVolvoEnabled,
          isPayAsYouDriveVolvoOctEnabled,
          isPayAsYouDriveVolvoNovEnabled,
        }
      )
    );
  }, [
    isPayAsYouDriveVolvoEnabled,
    isPayAsYouDriveVolvoOctEnabled,
    isPayAsYouDriveVolvoNovEnabled,
    make,
    model,
    year,
    smartCharger,
    hasSolarPanels,
    hasInternetAccess,
  ]);

  const isEligibleVWG = useMemo(
    () => isPayAsYouDriveVWGEnabled && eligibleForVWGPromo(make, model, year),
    [isPayAsYouDriveVWGEnabled, make, model, year]
  );

  const integrationType = useMemo(
    () =>
      getIntegrationType({
        charger: smartCharger,
        vehicle: { make, model, year },
        hasSolar: !!hasSolarPanels,
        hasVehicleInternet: !!hasInternetAccess,
        featureFlags: {
          payAsYouDriveVolvo: isPayAsYouDriveVolvoEnabled,
          payAsYouDriveVolvoOct: isPayAsYouDriveVolvoOctEnabled,
          payAsYouDriveVolvoNov: isPayAsYouDriveVolvoNovEnabled,
          isIndraChargeAnytimeApp: isIndraChargeAnytimeAppEnabled,
        },
      }),
    [
      smartCharger,
      make,
      model,
      year,
      hasSolarPanels,
      hasInternetAccess,
      isPayAsYouDriveVolvoEnabled,
      isPayAsYouDriveVolvoOctEnabled,
      isPayAsYouDriveVolvoNovEnabled,
      isIndraChargeAnytimeAppEnabled,
    ]
  );

  const validatePromoCode = useCallback(
    (isPromoCodeValid: boolean) => {
      if (promoCode) {
        setIsPromoCodeError(isPromoCodeValid);
        if (!isPromoCodeValid) {
          setValidPromoCode(promoCode);
        }
      } else {
        setIsPromoCodeError(true);
      }
    },
    [promoCode]
  );

  return (
    <ChargeAnytimeContext.Provider
      value={{
        smartCharger,
        setSmartCharger,
        make,
        setMake,
        model,
        setModel,
        year,
        setYear,
        hasInternetAccess,
        setHasInternetAccess,
        hasSolarPanels,
        setHasSolarPanels,
        eligibility,
        isError,
        isFetching: !!isFetching,
        isPayAsYouDriveEnabled,
        isPayAsYouDriveVolvoEnabled,
        isPayAsYouDriveVolvoOctEnabled,
        isPayAsYouDriveVolvoNovEnabled,
        isPayAsYouDriveVWGEnabled,
        isIndraChargeAnytimeAppEnabled,
        hasSmartMeter,
        ineligibleNotSmartMeter,
        ineligibleInvalidMeterReadFrequency,
        ineligibleOnlyGas,
        ineligibleOther,
        integrationType,
        // volvo
        isEligibleVolvo,
        // vwg
        isEligibleVWG,
        vwgOrderNumber,
        setVwgOrderNumber,
        vwgOrderNumberError,
        setVwgOrderNumberError,
        // promo
        validatePromoCode,
        promoCode,
        validPromoCode,
        setPromoCode,
        isPromoCodeError,
      }}
    >
      {children}
    </ChargeAnytimeContext.Provider>
  );
};
