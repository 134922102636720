import { Margin, PrimaryCTAButton, SecondaryCTAButton } from '@ovotech/nebula';
import { AnalyticsClick } from '@ovotech/ui-tools';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { HomeProfileData } from '../../../types';
import { homeProfileModalTestIDs as testIDs } from '../testIDs';
import FormProgress from './FormProgressTracker/FormProgressTracker';
import { FormProgressTrackerContainer } from './FormProgressTracker/FormProgressTracker.styled';
import {
  HomeProfileFormButtonNav,
  HomeProfileFormContainer,
} from './HomeProfileForm.styled';

import { HomeProfileFormWizard } from './HomeProfileFormWizard';
import { HomeProfileFormWizardStep } from './HomeProfileFormWizardStep';
import { homeProfileConfig } from './config';
import { checkStepDataIsFilled, filterEmptyObjectFields } from './validateForm';
import { useHomeProfileMutation } from '@/src/api/use-home-profile-mutation';
import { ENERGY_INSIGHTS } from '@/src/constants/analytics';
import { PrivacyPolicyNotice } from './PrivacyPolicyNotice';

const NUMBER_OF_QUESTIONS = homeProfileConfig.numberOfQuestions;

export type HomeProfileFormProps = {
  existingHomeProfileData?: HomeProfileData | null;
  onBackFromFirstQuestion: VoidFunction;
  onNextFromLastQuestion: VoidFunction;
};

export const HomeProfileForm = ({
  existingHomeProfileData,
  onBackFromFirstQuestion,
  onNextFromLastQuestion,
}: HomeProfileFormProps) => {
  const [step, setStep] = useState(1);
  const [formError, setFormError] = useState(false);
  const putHomeProfileData = useHomeProfileMutation();

  const methods = useForm<HomeProfileData>({
    defaultValues: existingHomeProfileData || undefined,
  });

  const { getValues, formState } = methods;

  const completedAnswers = filterEmptyObjectFields(getValues());
  const dirtyFieldsSet = new Set(Object.keys(formState.dirtyFields));
  const stepConfig = homeProfileConfig.getQuestionStepConfig(step);
  const isCurrentStepDirty = dirtyFieldsSet.has(stepConfig.fieldSetName);
  const isCurrentAnswerValid =
    checkStepDataIsFilled(step, completedAnswers) ||
    isCurrentStepDirty ||
    stepConfig.optional;

  const isNextDisabled =
    !stepConfig.optional && (formError || !isCurrentAnswerValid);

  useEffect(() => {
    if (isCurrentStepDirty) {
      setFormError(!isCurrentAnswerValid);
    }
  }, [isCurrentAnswerValid, isCurrentStepDirty]);

  const onSubmit = methods.handleSubmit(homeProfileData => {
    const filteredObject = filterEmptyObjectFields(homeProfileData);

    if (putHomeProfileData.status !== 'loading') {
      putHomeProfileData.mutate(filteredObject);
    }

    onNextFromLastQuestion();
  });

  return (
    <FormProvider {...methods}>
      <HomeProfileFormContainer>
        <form data-testid={testIDs.form.container} onSubmit={onSubmit}>
          <Margin bottom={2}>
            <HomeProfileFormWizard step={step} formError={formError} />
          </Margin>

          <Margin bottom={6}>
            <PrivacyPolicyNotice />
          </Margin>

          <FormProgressTrackerContainer>
            <FormProgress questionLength={NUMBER_OF_QUESTIONS} step={step} />
          </FormProgressTrackerContainer>

          <HomeProfileFormButtonNav>
            {/* PREVIOUS OR BACK BUTTON */}
            {step === 1 ? (
              <SecondaryCTAButton
                type="button"
                data-testid={testIDs.form.previous(step)}
                onClick={onBackFromFirstQuestion}
                fullWidth="never"
                iconLeft="chevron-left"
              >
                Back
              </SecondaryCTAButton>
            ) : (
              <SecondaryCTAButton
                type="button"
                data-testid={testIDs.form.previous(step)}
                onClick={() => {
                  setFormError(false);
                  setStep(step - 1);
                }}
                fullWidth="never"
                iconLeft="chevron-left"
              >
                Previous
              </SecondaryCTAButton>
            )}

            {/* NEXT OR SUBMIT BUTTON */}
            <HomeProfileFormWizardStep visible>
              {step < NUMBER_OF_QUESTIONS ? (
                <PrimaryCTAButton
                  type="button"
                  data-testid={testIDs.form.next(step)}
                  iconRight="chevron-right"
                  onClick={() => {
                    setFormError(isNextDisabled);

                    if (!isNextDisabled) {
                      setStep(step + 1);
                    }
                  }}
                >
                  Next
                </PrimaryCTAButton>
              ) : (
                <AnalyticsClick
                  name={ENERGY_INSIGHTS.BUTTON_CLICK_HOME_PROFILE_FINISH_BUTTON}
                >
                  <PrimaryCTAButton
                    type="submit"
                    data-testid={testIDs.form.submit}
                    aria-label="Submit home profile form"
                    onClick={(e: React.MouseEvent<HTMLElement>) => {
                      if (isNextDisabled) {
                        e.preventDefault();
                        return setFormError(true);
                      }
                      setFormError(false);
                    }}
                  >
                    Next
                  </PrimaryCTAButton>
                </AnalyticsClick>
              )}
            </HomeProfileFormWizardStep>
          </HomeProfileFormButtonNav>
        </form>
      </HomeProfileFormContainer>
    </FormProvider>
  );
};
